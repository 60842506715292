.full-terms {
  text-align: justify;

  h4 {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: bold;
  }
}
