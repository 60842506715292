.actual-terms {
  ol {
    font-weight: bold;

    li {
      margin: 20px 0 0 0;
      font-weight: lighter;
    }
  }
}
