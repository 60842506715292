.about {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;

    .text-area {
      p {
        text-align: justify;
      }
    }

    .gp-image {
      width: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    .text-area {
      p {
        text-align: justify;
      }
    }

    .gp-image {
      float: right;
      width: 500px;
      shape-outside: url(/images/gp-image.jpg);
      shape-margin: 20px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }
}
