@import "../../assets/styles/variables.scss";

.sticky-nav {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
  box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
}

.navbar-default {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  .top-header {
    background-color: #2c368f;

    .social-wrapper {
      .social-container {
        background-color: $swSecondaryColor;
        margin-right: 10px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 1s all;
        padding: 10px;
        border-radius: 50%;

        .social-icon {
          color: #fff;
          margin-top: 3px;
        }

        &:hover {
          background-color: #212529;
        }
      }
    }
  }

  .container-style {
    max-width: 1110px;
    padding: 2;
  }

  .collapse {
    width: 100%;
    justify-content: flex-end;
  }

  .nav-item {
    margin: 0 50 !important;
  }

  .navbar-nav {
    padding: 10px 0px;
  }
  .navbar-nav > li {
    margin-right: 50px;
  }
  .navbar-nav > li:last-child {
    margin-right: 0;
  }
  .navbar-nav > li > a,
  .dropdown-menu a {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .navbar-nav > li > a {
    padding: 4px 25px;
  }

  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background-color: transparent;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown:not(:hover) .dropdown-menu {
    display: none;
  }

  .dropdown-attach-menu-to-parent {
    position: absolute !important;
    margin-top: -15px;
  }

  .navbar-collapse,
  .navbar-form {
    border-color: rgba(231, 231, 231, 0.15);
  }

  .navbar-brand {
    img {
      padding: 15px 0;
      max-height: 100px;
    }
  }
}

.dropdown-menu {
  border: none;
  top: 150%;
}
.dropdown-menu a {
  padding: 5px 20px;
  background: none !important;
  outline: none !important;
}

.dropdown-menu a:hover {
  background-color: $swBtnSecondaryColor !important;
}

@media (max-width: 767px) {
  // .navbar-default {
  //   background-color: #000;
  // }
  // .navbar-nav > .active > a,
  // .navbar-nav > .active > a:hover,
  // .navbar-nav > .active > a:focus,
  // .navbar-nav > li > a:hover {
  //   color: #6c4e9f !important;
  // }

  .navbar-brand {
    img {
      max-height: 80px !important;
    }
  }

  .navbar-nav {
    padding: 20px 10px !important;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    background: none;
  }
}

@media (max-width: 991px) {
  .navbar-default {
    .navbar-nav {
      padding: 20px 10px !important;
    }
  }
}

.highlighted > a {
  color: #ffffff !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.highlighted {
  background-color: #363948;
  // padding-left: 20px;
  // padding-right: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  a {
    color: white;
    letter-spacing: 1.5px;
  }
}

.highlighted:hover {
  background-color: white;
}
