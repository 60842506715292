$headingFontColor: #30a5df;
$headingFontFamily: "Open Sans", sans-serif;
$defaultFontFamily: "Open Sans", sans-serif;
$paragraphColor: #333333;
$labelColor: #b2b4b2;

$swPrimaryColor: #2c368f;
$swSecondaryColor: #30a5df;
$swTertiaryColor: grey;

$swBtnPrimaryColor: #2c368f;
$swBtnPrimaryHoverColor: #2aa4dd;
$swBtnSecondaryColor: #30a5df;
$swBtnSecondaryHoverColor: $swTertiaryColor;

$swAltColor: #179ea3;
$swErrorColor: #ad0c0c;

$navBarBgColor: #fff;
$navBarItemBgColor: #363948;
$navBarItemColor: #363948;
$navBarItemHoverColor: #fff;
$navBarDropDownMenuBgColor: #2c368f;
