@import "./../../../assets/styles/variables.scss";

.title-header {
  height: 20vh;
  min-height: 300;
  position: relative;
  background-image: url('/images/gp-image-small-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h1 {
    color: #fff !important;
    font-weight: 900 !important;
    letter-spacing: 2px !important;
    font-size: 52px;
    background-clip: text;
  }
}
