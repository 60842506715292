
.datatableSearch {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  /* border-top: 2px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.rt-th {
  text-align: left;
  font-weight: 500;
}


