.details {
  .main-wrapper {
    height: 300px;
    position: relative;

    .card-img {
      position: absolute;
      height: 300px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1 !important;
      filter: brightness(60%) !important;
      transition: all 1s;
      z-index: 1;
    }

    .card-text {
      position: absolute;
      height: 300px;
      z-index: 1;
      width: 100%;
      transition: all 1s;

      a {
        position: absolute;
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          text-decoration: none;
        }
        h3 {
          text-align: center;
          color: #fff !important;
          font-weight: 500;
          text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }

    &:hover .card-img {
      filter: brightness(90%) !important;
    }

    &:hover .card-text {
      transform: scale(1.025);
      transition: all 0.5s;
      cursor: pointer;

      h3 {
        font-weight: 600;
      }
    }
  }
}
