.dynamic-radio-group {
  .option-label {
    position: relative;
    bottom: 2px;
  }

  .error-message {
    position: relative;
    top: -5px;
  }

  .radio-items {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }
}
