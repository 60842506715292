#signaturePad {
  width: 100%;

  .signature-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }

  .signature-toolBox {
    background-color: #e8e8e8;
  }

  .signature-box-item {
    border-radius: 2px;
    border: 1px solid #eaeaea;
  }
}
