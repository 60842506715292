.image-header {
  height: 60vh;
  min-height: 300;
  position: relative;

  .image-container {
    height: 60vh;
    min-height: 300;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(70%);
    z-index: 1 !important;
  }

  .text-container {
    z-index: 2 !important;
    height: 70vh;
    min-height: 300;
    position: absolute;
    top: 0;

    h1 {
      color: #fff !important;
      font-weight: 700 !important;
      letter-spacing: 5px !important;
      filter: brightness(100%) !important;
      text-transform: uppercase !important;
      text-align: center;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    }
  }
}
