@import "../../../../../assets/styles/media-queries.scss";
@import "../../../../../assets/styles/variables.scss";

@mixin light-style {
  height: 112px;
  background-color: darken(#fff, 1%);
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  transition: all 0.25s;
  font-family: "sofia pro regular";

  .main-row {
    height: 100%;
    text-align: center;

    @include for-phone-only {
      .title {
        margin-top: 0.5rem;
      }
    }

    .icon-col {
      border: none;

      .icon {
        height: 70px;
        width: 70px !important;
        border-radius: 50%;
        background-color: transparent;
        text-align: center;
        width: 100% !important;
        height: 100% !important;

        .icon-style {
          color: $swPrimaryColor;
          font-size: 2rem;
          text-justify: center;
        }
      }
    }
  }
}

@mixin dark-style {
  background-color: $swPrimaryColor;
  cursor: pointer;
  color: white;
  text-decoration: inherit;
  transition: all 0.25s;
  height: 112px;
  font-size: 12px;

  .main-row {
    height: 100%;
    text-align: center;

    .icon-col {
      border: none;

      .icon {
        background-color: transparent;
        text-align: center;
        width: 100% !important;
        height: 100% !important;

        .icon-style {
          color: white;
          font-size: 2rem;
          text-justify: center;
        }
      }
    }
  }
}

.link-card-light {
  .container {
    @include light-style;
  }

  .container:hover {
    @include dark-style;
  }
}

.link-card-dark {
  .container {
    @include dark-style;
  }

  .container:hover {
    @include light-style;
  }
}
